import axios from '@/utils/axios';
const baseURL = '/api'
// 服务

// 服务列表
export const getserveList = (params) => {
  return axios({
    baseURL,
    url: '/appMenu/routes',
    method: 'get',
    params
  });
};