<!--
* @file    :   community.vue
* @time    :   2022/11/29 16:04:23
* @author  :   jxbzz
* @version :   1.0
* @license :   (C)Copyright 2022 jxbzz
* @desc    :   None
* Created by vscode
-->
<template>
  <div class="con">
    <div class="layouter">
      <div class="search-box">
        <input type="text" name="search" class="search-inpt" v-model="name" @change="toSearch" placeholder="请输入关键字搜索">
        <img src="@/assets/icon_search.png" @click="toSearch" alt="" class="search-icon">
      </div>
    </div>
    <van-skeleton v-if="loading" title :row="10" />
    <template v-else-if="!searchKey">
      <van-row gutter="20" class="links-list" v-for="(val,inx) in listData" :key="inx">
        <!-- 费用缴纳 -->
        <van-col span="24">
          <h4 class="links-title">{{val.name}}</h4>
          <van-row type="flex" class="links">
          </van-row>
        </van-col>
        <!-- 生活服务 -->
        <van-col span="24">
          <!-- <h4 class="links-title">生活服务</h4> -->
          <van-row type="flex" class="links">
            <template  v-for="(item,index) in val.children" >
              <van-col span="6" class="link-item" :key="index" v-if="item.isOpenWeApp=='true'">
                <div class="div">
                  <img :src="item.icon" alt="" />
                  <span class="title">{{item.name}}</span>
                  <wx-open-launch-weapp
                    id="launch-btn"
                    style="position: absolute; top: 0; width: 100%; height: 100%"
                    :username="item.userName"
                    :path="item.path"
                  >
                    <script type="text/wxtag-template">
                      <style>
                        .box_a {
                          position: absolute;
                          top: 0;
                          width: 100%;
                          height: 100%;
                        }
                      </style>
                      <div class="box_a"></div>
                    </script>
                  </wx-open-launch-weapp>
                </div>
              </van-col>
              <van-col span="6" class="link-item" :key="'link'+index" v-else-if="item.isLink=='true'">
                <a class="div" :href="item.path" > 
                  <img :src="item.icon" alt="" />
                  <span>{{item.name}}</span>
                </a>
              </van-col>
              <template v-else-if="item.path=='/myWorkList'">
                <van-col span="6" class="link-item" :key="'myWorkList'+index" v-if="isShowRepair">
                  <a class="div" @click="gopage(item.path)" >
                    <img :src="item.icon" alt="" />
                    <span>{{item.name}}</span>
                  </a>
                </van-col>
              </template>
              <van-col span="6" class="link-item" :key="'path'+index" v-else>
                <a class="div" @click="gopage(item.path)">
                  <img :src="item.icon" alt="" />
                  <span>{{item.name}}</span>
                </a>
              </van-col>
            </template>
          </van-row>
        </van-col>
      </van-row>
    </template>
    <template v-else>
      <van-row type="flex" class="links">
        <van-col span="6" class="link-item"  v-for="(item,index) in listData" :key="index">
          <div class="div" v-if="item.isOpenWeApp=='true'">
            <img :src="item.icon" alt="" />
            <span class="title">{{item.name}}</span>
            <wx-open-launch-weapp
              id="launch-btn"
              style="position: absolute; top: 0; width: 100%; height: 100%"
              :username="item.userName"
              :path="item.path"
            >
              <script type="text/wxtag-template">
                <style>
                  .box_a {
                    position: absolute;
                    top: 0;
                    width: 100%;
                    height: 100%;
                  }
                </style>
                <div class="box_a"></div>
              </script>
            </wx-open-launch-weapp>
          </div>
          <a class="div" :href="item.path" v-else-if="item.isLink=='true'"> 
            <img :src="item.icon" alt="" />
            <span>{{item.name}}</span>
          </a>
          <template v-else-if="item.path=='/myWorkList'">
            <a class="div" @click="gopage(item.path)" v-if="isShowRepair">
              <img :src="item.icon" alt="" />
              <span>{{item.name}}</span>
            </a>
          </template>
          <a class="div" @click="gopage(item.path)" v-else>
            <img :src="item.icon" alt="" />
            <span>{{item.name}}</span>
          </a>
        </van-col>
      </van-row>
    </template>


    <!-- tabbar -->
    <van-tabbar v-model="active" @change="onChange" z-index="10" active-color="#2f54eb" placeholder>
      <van-tabbar-item name="/home">
        <span>首页</span>
        <template #icon="props">
          <img :src="props.active ? icon.home_act : icon.home" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="/community">
        <span>服务</span>
        <template #icon="props">
          <img :src="props.active ? icon.serve_act : icon.serve" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item  name="/my">
        <span>我的</span>
        <template #icon="props">
          <img :src="props.active ? icon.my_act : icon.my" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { removeToken, removeUsernfo } from '@/utils/token.js'
import { getserveList } from '@/api/community'
import { getWxConfigs } from '@/api/wxApi'
import wx from 'weixin-jsapi'
import { myProperty, myEstateList } from '@/api/houseApi.js'
export default {
  name: 'Home',
  components: {},
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: 100,
    },
  },
  data() {
    return {
      active: "/community",
      icon: {
        home_act: require('@/assets/tabbar/home_act.png'),
        home: require('@/assets/tabbar/home.png'),
        serve_act: require('@/assets/tabbar/serve_act.png'),
        serve: require('@/assets/tabbar/serve.png'),
        my_act: require('@/assets/tabbar/my_act.png'),
        my: require('@/assets/tabbar/my.png'),
      },
      wxHtml: '',
      loading: false,
      isShowRepair: false,
      isSpecial: false,
      hasRoom: false,
      showChangeEstate: false,
      estateList: [],
      // 搜索
      name: "",
      searchKey: '',
      userInfo: this.$store.getters.userInfo,
      listData: [
        {
          // 大类标题
          name: "便民服务",
          id: 1,
          children: [
            {
              icon: "/services/chongdian.png",
              path: "pages/PowerStation/index",
              isOpenWeApp: true,
              isLink: false,
              userName: "gh_d3053e832a09",
              name: "智能充电",
            },
            {
              //图标
              icon: "/services/shenghuo.png",
              // 导航地址
              path: "main/pages/nativeindex/nativeindex",
              // 是否跳转小程序
              isOpenWeApp: true,
              // 外部链接
              isLink: false,
              // 小程绑定的公众号id
              userName: "gh_aceb9bd462ab",
              // 名称
              name: "生活缴费",
            },
            {
              icon: "/services/bianmin.png",
              path: "views/index/service-center/service-center",
              isOpenWeApp: true,
              isLink: false,
              userName: "gh_e3e0d867dd82",
              name: "便民网购",
            },
            {
              icon: "/services/jiazheng.png",
              path: "pages/index/index",
              isOpenWeApp: true,
              isLink: false,
              userName: "gh_b0d39e167892",
              name: "家政服务",
            },
            {
              icon: "/services/tianqi.png",
              path: "http://wx.weather.com.cn/mweather/101120901.shtml#1",
              isOpenWeApp: false,
              isLink: true,
              userName: "",
              name: "天气信息",
            },
            {
              icon: "/services/fujin.png",
              path: "https://m.amap.com/",
              isOpenWeApp: false,
              isLink: true,
              userName: "",
              name: "附近",
            },
          ]
        },
        {
          name: "社区安全",
          id: 1,
          children: [
            {
              icon: "/services/jingqing1.png",
              path: "/warning",
              isOpenWeApp: false,
              isLink: false,
              userName: "",
              name: "警情上报",
            },
            {
              icon: "/services/yujing.png",
              path: "/warningInfo",
              isOpenWeApp: false,
              isLink: false,
              userName: "",
              name: "预警信息",
            },
            {
              icon: "/services/jingqing2.png",
              path: "/myWarningList",
              isOpenWeApp: false,
              isLink: false,
              userName: "",
              name: "我的警情上报",
            },
            {
              icon: "/services/sheqing.png",
              path: "/socialSharing",
              isOpenWeApp: false,
              isLink: false,
              userName: "",
              name: "社情反映",
            },
            {
              icon: "/services/sheqing2.png",
              path: "/socialSharingList",
              isOpenWeApp: false,
              isLink: false,
              userName: "",
              name: "社情共享",
            },
            {
              icon: "/services/jingqing3.png",
              path: "/alertIssued",
              isOpenWeApp: false,
              isLink: false,
              userName: "",
              name: "警情",
            },
            {
              icon: "/services/junren.png",
              path: "/veterans",
              isOpenWeApp: false,
              isLink: false,
              userName: "",
              name: "退役军人活动",
            },
          ]
        },
        {
          name: "社区服务",
          id: 1,
          children: [
            {
              icon: "/services/jiuye.png",
              path: "/employmentInformation",
              isOpenWeApp: false,
              isLink: false,
              userName: "",
              name: "就业信息",
            },
            {
              icon: "/services/fuwu.png",
              path: "/serviceApplication",
              isOpenWeApp: false,
              isLink: false,
              userName: "",
              name: "服务申请",
            },
            {
              icon: "/services/fuwu2.png",
              path: "/applicationList",
              isOpenWeApp: false,
              isLink: false,
              userName: "",
              name: "我的服务申请",
            },
            {
              icon: "/services/teshu.png",
              path: "/policyList",
              isOpenWeApp: false,
              isLink: false,
              userName: "",
              name: "特殊人群",
            },
            {
              icon: "/services/xuanmin.png",
              path: "/registrationVoters",
              isOpenWeApp: false,
              isLink: false,
              userName: "",
              name: "选民登记",
            },
          ]
        },
        {
          name: "智慧党建",
          id: 1,
          children: [
            {
              icon: "/services/sanwu.png",
              path: "/threeAffairs",
              isOpenWeApp: false,
              isLink: false,
              userName: "",
              name: "三务公开",
            },
            {
              icon: "/services/vote.png",
              path: "/voting",
              isOpenWeApp: false,
              isLink: false,
              userName: "",
              name: "居民投票",
            },
            {
              icon: "/services/yijian.png",
              path: "/opinion",
              isOpenWeApp: false,
              isLink: false,
              userName: "",
              name: "意见反馈",
            },
          ]
        },
        {
          name: "智慧物业",
          id: 1,
          children: [
            {
              icon: "/services/fangwu.png",
              path: "/houseRegister",
              isOpenWeApp: false,
              isLink: false,
              userName: "",
              name: "房屋登记",
            },
            {
              icon: "/services/renyuan.png",
              path: "/memberRegister",
              isOpenWeApp: false,
              isLink: false,
              userName: "",
              name: "人员登记",
            },
            {
              icon: "/services/fangchan.png",
              path: "/myProperty",
              isOpenWeApp: false,
              isLink: false,
              userName: "",
              name: "我的房产",
            },
            {
              icon: "/services/face.png",
              path: "/faceUP",
              isOpenWeApp: false,
              isLink: false,
              userName: "",
              name: "人脸录入",
            },
            {
              icon: "/services/shenhe.png",
              path: "/memberVerifyList",
              isOpenWeApp: false,
              isLink: false,
              userName: "",
              name: "住户审核",
            },
            {
              icon: "/services/baoxiu.png",
              path: "/repairSave",
              isOpenWeApp: false,
              isLink: false,
              userName: "",
              name: "物业报修",
            },
            {
              icon: "/services/baoxiu2.png",
              path: "/myRepairList",
              isOpenWeApp: false,
              isLink: false,
              userName: "",
              name: "我的报修记录",
            },
            {
              icon: "/services/suqiu.png",
              path: "/residentsAppeal",
              isOpenWeApp: false,
              isLink: false,
              userName: "",
              name: "居民诉求",
            },
            {
              icon: "/services/suqiu.png",
              path: "/myAppealList",
              isOpenWeApp: false,
              isLink: false,
              userName: "",
              name: "我的诉求",
            },
            {
              icon: "/services/tongzhi.png",
              path: "/emergencyNotification",
              isOpenWeApp: false,
              isLink: false,
              userName: "",
              name: "应急通知",
            },
            {
              icon: "/services/baoxiu3.png",
              path: "/myWorkList",
              isOpenWeApp: false,
              isLink: false,
              userName: "",
              name: "待处理报修",
            },
            {
              icon: "/services/jiaofei.png",
              path: "/propertyFee",
              isOpenWeApp: false,
              isLink: false,
              userName: "",
              name: "物业费缴纳",
            },
          ]
        },
        {
          name: "疫情防控",
          id: 1,
          children: [
            {
              icon: "/services/fangkong.png",
              path: "/preventionList",
              isOpenWeApp: false,
              isLink: false,
              userName: "",
              name: "防控信息",
            },
            {
              icon: "/services/code.png",
              path: "pages/index/index",
              isOpenWeApp: true,
              isLink: false,
              userName: "gh_77e3cb5f3991",
              name: "健康码查询",
            },
            {
              icon: "/services/hesuan.png",
              path: "pages/nucleic-acid-test/map/main",
              isOpenWeApp: true,
              isLink: false,
              userName: "gh_598eb49157c6",
              name: "核酸检测点",
            },
          ]
        },
        {
          name: "健康医疗",
          id: 1,
          children: [
            {
              icon: "/services/yisheng.png",
              path: "https://yjt.sddingkao.com/web/module/common/dist/#/doctor",
              isOpenWeApp: false,
              isLink: true,
              userName: "",
              name: "寻找医生",
            },
            {
              icon: "/services/yiyuan.png",
              path: "https://yjt.sddingkao.com/web/module/common/hospital/",
              isOpenWeApp: false,
              isLink: true,
              userName: "",
              name: "寻找医院",
            },
            {
              icon: "/services/yanglao.png",
              path: "elderly",
              isOpenWeApp: false,
              isLink: false,
              userName: "",
              name: "居家养老",
            },
          ]
        },
      ],
      // currentEstate: {}
    }
  },
  
  // watch
  computed: {
    estateName() {
      return this.$store.getters.currentEstate?this.$store.getters.currentEstate.deptName:''
      // return this.$store.getters.userInfo.estateName
    }
  },  

  // 创建完成（可以访问当前this实例）
  created() {
    let url = window.location.href.split('#')[0]
    getWxConfigs({ url: url }).then(res => {
      wx.config({
        debug: false,
        appId: res.data.appId,
        timestamp: '' + res.data.timestamp,
        nonceStr: res.data.nonceStr,
        signature: res.data.signature,
        jsApiList: ['chooseImage', 'previewImage'],
        openTagList: ['wx-open-launch-weapp'],
      })
    })
  },

  // 挂载完成（可以访问DOM元素）
  mounted() {
    // userinfo中：
    // repairUser 是否维修员
    // isSpecialPeople 是否特殊人群 0否1是
    this.isShowRepair = this.$store.state.user.userInfo.repairUser
    this.isSpecial = this.$store.state.user.userInfo.isSpecialPeople
    this.getList()
  },

  // 方法集合
  methods: {
    toSearch(key){
      this.searchKey = this.name
      this.getList()
    },
    getList(){
      this.loading = true
      getserveList({
        name: this.name,
      }).then(res => {
        this.loading = false
        if (res.code === 200) {
          this.listData = res.data
        }
      })
    },
    onChange (index) {
      this.$router.replace(index)
    },
    getEstateList() {
      myEstateList().then(res => {
        if(res.code == 200) {
          this.estateList = res.data
        }
      })
    },
    changeEstate() {
      if(!this.$store.getters.currentEstate) {
        return this.$toast({
          type: 'fail',
          message: '请先绑定房产！',
          duration: 1000,
          onClose: () => {
            this.$router.push({
              path: '/houseRegister',
            })
          }
        })
      }
      // this.showChangeEstate = true
      this.$router.push({
        path: '/estateList'
      })
    },
    // logUserinfo() {
    //   this.$store.commit('SET_USER_AUTH', {a:1}) 
    // },
    logout() {
      removeToken()
      removeUsernfo()
      
      this.$store.commit('SET_IS_LOADING', true)
      setTimeout(() => {
        this.$store.commit('SET_IS_LOADING', false)
        location.reload()
      }, 200)
    },
    GetQueryString(name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      var r = window.location.search.substr(1).match(reg)
      if (r != null) return unescape(r[2])
      return null
    },

    gopage(val) {
      if (this.$store.getters.userInfo.militaryService !== '3'&&val=="/veterans") {
        this.$toast.fail('您不是退役军人！')
        return
      }
      if (this.isSpecial == 0&&val=="/policyList") {
        this.$toast.fail('您不是特殊人群！')
        return
      }
      if(val=="logout"){
        return this.logout()
      }
      // if(val=="/propertyRepair"){
      //   return this.goRepair()
      // }
      // window.location.href = val
      this.$router.push({
        path: val
      })
    },
    goRepair(val) {
      myProperty().then(res => {
        if(res.code == 200) {
          let list = res.data
          if(list.length>0) {
            window.location.href = val
          } else {
            return this.$toast({
              type: 'fail',
              message: '请先绑定房产！',
              duration: 1000,
              onClose: () => {
                this.$router.push({
                  path: '/houseRegister',
                })
              }
            })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.con {
  background-color: #fff;
  min-height: 100vh;
}
.layouter{
  padding: 16px 20px;
}
.search-box{
  background: #F2F2F2;
  display: flex;
  align-items: center;
  padding: 0 12px;
}
.search-inpt{
  flex: 1;
  font-size: 13px;
  background:none;  
	outline:none;  
	border:none;
  height: 36px;
}
.search-inpt::placeholder{
  color: #999;
}
.search-icon{
  width: 18px;
}
.banner {
  width: 350px;
  margin: 0 auto;
  border-radius: 12px;
  overflow: hidden;
}
#launch-btn {
  position: relative;
  top: 0;
  z-index: 9999;
  display: block;
  width: 100%;
  height: 100%;
}

.swipe-item {
  color: #fff;
  font-size: 20px;
  // line-height: 150px;
  // height: 149px;
  text-align: center;
  background-color: #39a9ed;
  height: 140px;
  img {
    width: 100%;
    // height: auto;
    height: 140px;
  }
}
.userBtn {
  width: 100%;
  padding: 10px 0;
}
.links-list {
  margin: 12px 10px;
  margin-top: 0;
  .links-title {
    color: #434343;
    font-size: 16px;
    padding-left: 10px;
    margin-bottom: 8px;
  }
}

.links {
    text-align: center;
  }
.link-item {
  // border: 1px solid #eeeeee;
  // height: 110px;
  // margin: 10px 0;
  // width: 23%;
  margin-top: 10px;
  margin-bottom: 10px;
  // margin-right: 2.66%;
  // border-radius: 10px;
  // &:nth-of-type(4n) {
  //   margin-right: 0;
  // }
  .div {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: #fff;
    border-radius: 8px;
    transition: transform 0.3s;
    line-height: 24px;
    img {
      width: 48px;
      height: 48px;
      margin-bottom: 10px;
    }
    i {
      width: 36px;
      height: 36px;
      font-size: 30px;
      margin-bottom: 10px;
    }
    span {
      color: #2e2e2e;
      font-size: 14px;
    }
  }
}

.estateBox {
  // width: 100%;
  width: fit-content;
  height: 50px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  font-size: 20px;
  padding: 0 12px;
  color: #313131;
  .name {
    margin-left: 3px;
    font-size: 16px;
  }
  .down {
    transform: rotate(90deg);
  }
}
</style>
